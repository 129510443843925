
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.icon {
    border-radius: 50%;
    width: 140px;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.wrapper{
    position: relative;
    padding: 20px 0;
    &::before{
        content: "";
        position: absolute;
        width: 100vw;
        height: 100%;
        background: linear-gradient(180deg, #F0F6FC -15.34%, rgba(255, 255, 255, 0) 108.22%);
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;
    }
}